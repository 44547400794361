@font-face {
  font-family: "Museo Sans";
  src: local("Museo Sans 300"),
    url("./assets/fonts/Museo Sans/MuseoSans_300.woff2") format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Museo Sans";
  src: local("Museo Sans 500"),
    url("./assets/fonts/Museo Sans/MuseoSans_500.woff2") format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "Museo Sans";
  src: local("Museo Sans 700"),
    url("./assets/fonts/Museo Sans/MuseoSans_700.woff2") format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: "Museo Sans";
  src: local("Museo Sans 900"),
    url("./assets/fonts/Museo Sans/MuseoSans_900.woff2") format("woff2");
  font-weight: 900;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: local("Brandon Grotesque Light"),
    url("./assets/fonts/Brandon-Grotesque-Font-Family/brandon-grotesque-light.woff2")
      format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: local("Brandon Grotesque Bold"),
    url("./assets/fonts/Brandon-Grotesque-Font-Family/brandon-grotesque-bold-regular.woff2")
      format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: local("Brandon Grotesque Black"),
    url("./assets/fonts/Brandon-Grotesque-Font-Family/brandon-grotesque-black.woff2")
      format("woff2");
  font-weight: 900;
}

.hamburger-react > div {
  height: 4px !important;
  border-radius: 5px;
  width: 30px !important;
  background: linear-gradient(90deg, #5f72ff 0%, #73d6f1 100%) !important;
}
